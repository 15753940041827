import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <div className="hero bg-indigo-800 pb-64 text-center">
      <h1 className="font-serif text-4xl md:pt-10 md:pb-16 md:text-7xl py-10 text-4xl text-white">Thank you!</h1>
      <p className="md:pb-20 md:text-xl text-indigo-300">We have received your request and will be in contact shortly.</p>
    </div>
  </Layout>
)
